// Code Base: http://jsfiddle.net/yetanrj6/
// Found here: https://stackoverflow.com/questions/27430490/continuous-logo-carousel
jQuery(function($){

  // Check Window width
  const mqCheck = window.matchMedia("(min-width:1440px)")
  // Carousel Wrapper element
  var $carousel = $(".carousel-wrapper");
  // Single Item size and spacing
  var carouselItemSpacing = 40;
  var carouselItemWidth = 180 + carouselItemSpacing;
  // Adapt variables to window width
  if(mqCheck.matches) {
    var carouselItemSpacing = 100;
    carouselItemWidth = 220 + carouselItemSpacing;
  }
  // Carousel rotation speed
  var carouselSpeed = 4000;

  // Set total carousel width (needed for animation)
  $carousel.css( "width", $carousel.children().length * carouselItemWidth );
  // Set the width + padding for all items
  $(".carousel-item").each( function() {
    $(this).css({
      "width": carouselItemWidth + "px",
      "padding": "0 " + ( carouselItemSpacing / 2 ) + "px"
    });
  });

  function carouselRotate() {
    // Get first item and animate
    var $firstItem = $carousel.find(".carousel-item:first");
    $firstItem.animate(
      {
        "margin-left": "-" + carouselItemWidth
      },
      carouselSpeed,
      "linear",
      // Afetr animation is done...
      function() {
        // ... remove and reset item css
        $firstItem.remove().css({ "margin-left": "0px" });
        // + insert the first item after the last one = loop
        $carousel.find(".carousel-item:last").after( $firstItem );
      }
    );
    requestAnimationFrame(carouselRotate);
  }

  // Start rotation without delay
  requestAnimationFrame(carouselRotate);

});